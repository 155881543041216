import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { MenuService } from '../../service/menu.serive';

import { MenuComponent } from './menu.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
    ],
    exports: [
        MenuComponent
    ],
    declarations: [
        MenuComponent
    ],
    providers: [MenuService],
    bootstrap: [MenuComponent]
})
export class MenuModule { }
