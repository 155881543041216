import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./page/home/home.module').then(m => m.HomeModule) },
  { path: 'about', loadChildren: () => import('./page/about/about.module').then(m => m.AboutModule) },
  { path: 'contact', loadChildren: () => import('./page/contact/contact.module').then(m => m.ContactModule) },
  { path: 'news', loadChildren: () => import('./page/news/news.module').then(m => m.NewsModule) },
  { path: 'projects', loadChildren: () => import('./page/projects/projects.module').then(m => m.ProjectsModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
