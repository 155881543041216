import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class MenuLinkService {

    menuLink: BehaviorSubject<string> = new BehaviorSubject<string>('/home');

    selLink(link: string) {
        this.menuLink.next(link);
    }

    getLink() {
        return this.menuLink;
    }
}