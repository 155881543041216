import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MenuLinkService } from '../../service/menu-link.service';
import { MenuService } from '../../service/menu.serive';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {

  /**
   * 目录配置
   */
  menu: any[] = [];

  /**
   * 选中模块
   */
  actItem: any = undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private menuLinkService: MenuLinkService,
    private menuService: MenuService,
  ) {}

  ngOnInit() {
    /* 获取菜单配置 */
    this.menuService.getConfig().subscribe((ret: any[]) => {
      this.menu = ret;
      /* 订阅 footer 中选择的菜单项 */
      this.menuLinkService.getLink().subscribe(ret => {
        this.menuAct(ret);
      });
    });
  }

  /**
   * 选择菜单中某模块
   * @param link 模块路由
   */
  menuAct(link: string) {
    /* 循环找出选中模快 */
    this.actItem = this.menu.find(e => e.link == link );
    
    /* 循环设置样式 */
    this.menu.forEach(e => {
      if (e.link == link) {
        if (this.actItem.color == 'font-w') {
          e['current'] = ['font-b', 'menu-act'];
        } else {
          e['current'] = ['font-w', 'menu-act'];
        }
      } else {
        e['current'] = [this.actItem.color, 'menu-unact'];
      }
    });
    /* 路由跳转 */
    this.router.navigate([`./${this.actItem.link}`], {relativeTo: this.route});
  }
}
